import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'login',
    },
  },
  // 管理员页面  gongchecarList
  {
    name: 'admingongchecarList',
    path: '/admingongchecarList',
    component: () => import('./view/admin/gongchecarList'),
    meta: {
      title: '客户供车列表',
    },
  },
  {
    name: 'admingongcheCarDetails',
    path: '/admingongcheCarDetails',
    component: () => import('./view/admin/gongchecarDetail'),
    meta: {
      title: '车辆详细信息',
    },
  },
  {
    name: 'adminAnalysis',
    path: '/adminAnalysis',
    component: () => import('./view/admin/analysis'),
    meta: {
      title: '统计分析',
    },
  },
  {
    name: 'adminCustomerList',
    path: '/adminCustomerList',
    component: () => import('./view/admin/customerList'),
    meta: {
      title: '客户列表',
    },
  },
  {
    name: 'adminCustomerDetail',
    path: '/adminCustomerDetail',
    component: () => import('./view/admin/customerDetail'),
    meta: {
      title: '查看客户信息',
    },
  },
  {
    name: 'adminCarList',
    path: '/adminCarList',
    component: () => import('./view/admin/carList'),
    meta: {
      title: '车辆列表',
    },
  },
  {
    name: 'adminCarDetails',
    path: '/adminCarDetails',
    component: () => import('./view/admin/carDetail'),
    meta: {
      title: '车辆详细信息',
    },
  },
  {
    name: 'adminPaymentList',
    path: '/adminPaymentList',
    component: () => import('./view/admin/paymentList'),
    meta: {
      title: '仓库信息',
    },
  },
  {
    name: 'adminOutboundDetail',
    path: '/adminOutboundDetail',
    component: () => import('./view/admin/outboundDetail'),
    meta: {
      title: '出库历史记录',
    },  
  },
  // 配件管理页
  {
    name: 'manage',
    path: '/manage',
    component: () => import('./view/parts/1manage'),
    meta: {
      title: '配件管理',
    },
  },
  {
    name: 'release',
    path: '/release',
    component: () => import('./view/parts/1release'),
    meta: {
      title: '配件发布',
    },
  },
  {
    name: 'update',
    path: '/update',
    component: () => import('./view/parts/1update'),
    meta: {
      title: '配件信息修改',
    },
  },
  {
    name: 'order',
    path: '/order',
    component: () => import('./view/parts/1order'),
    meta: {
      title: '订单管理',
    },
  },
  {
    name: 'orderDetail',
    path: '/orderDetail',
    component: () => import('./view/parts/1orderDetail'),
    meta: {
      title: '订单信息',
    },
  },
  // 商品页
  {
      name: 'goods',
      path: '/goods',
      component: () => import('./view/parts/goods'),
      meta: {
        title: '配件查找',
      },
    },
    {
      name: 'goodsDetail',
      path: '/goodsDetail',
      component: () => import('./view/parts/goodsDetail'),
      meta: {
        title: '配件详细',
      },
    },
    {
      name: 'goodsMy',
      path: '/goodsMy',
      component: () => import('./view/parts/my'),
      meta: {
        title: '我的购买记录',
      },
    },
    {
      name: 'myDetail',
      path: '/myDetail',
      component: () => import('./view/parts/myDetail'),
      meta: {
        title: '配件详细',
      },
    },
  // 登录页
  {
    name: 'login',
    path: '/login',
    component: () => import('./view/login'),
    meta: {
      title: '收车系统',
    },
  },
  // 财务页面
  {
    name: 'financeCarList',
    path: '/financeCarList',
    component: () => import('./view/finance/carList'),
    meta: {
      title: '车辆信息',
    },
  },
  {
    name: 'financePaymentInfo',
    path: '/financePaymentInfo',
    component: () => import('./view/finance/paymentInfo'),
    meta: {
      title: '查看付款信息',
    },
  },
  {
    name: 'financeCarDetails',
    path: '/financeCarDetails',
    component: () => import('./view/finance/carDetails'),
    meta: {
      title: '车辆详细信息',
    },
  },
  // 收车员页面
  {
    name: 'collectorgongchecarList',
    path: '/collectorgongchecarList',
    component: () => import('./view/collector/gongchecarList'),
    meta: {
      title: '客户供车列表',
    },
  },
  {
    name: 'collectorgongcheCarDetails',
    path: '/collectorgongcheCarDetails',
    component: () => import('./view/collector/gongchecarDetail'),
    meta: {
      title: '车辆详细信息',
    },
  },
  {
    name: 'collectorCustomerList',
    path: '/collectorCustomerList',
    component: () => import('./view/collector/customerList'),
    meta: {
      title: '客户列表',
    },
  },
  {
    name: 'collectorAddCustomer',
    path: '/collectorAddCustomer',
    component: () => import('./view/collector/addCustomer'),
    meta: {
      title: '新增客户信息',
    },
  },
  {
    name: 'collectorCustomerDetail',
    path: '/collectorCustomerDetail',
    component: () => import('./view/collector/customerDetail'),
    meta: {
      title: '客户详细信息',
    },
  },
  {
    name: 'collectorCarList',
    path: '/collectorCarList',
    component: () => import('./view/collector/carList'),
    meta: {
      title: '车辆列表',
      keepAlive: true,
      isNeedRefresh: true
    },
  },
  {
    name: 'collectorCarDetails',
    path: '/collectorCarDetails',
    component: () => import('./view/collector/carDetails'),
    meta: {
      title: '车辆详细信息',
    },
  },
  {
    name: 'collectorAddCar',
    path: '/collectorAddCar',
    component: () => import('./view/collector/addCar'),
    meta: {
      title: '新增车辆信息',
    },
  },
  {
    name: 'collectorAnalysis',
    path: '/collectorAnalysis',
    component: () => import('./view/collector/analysis'),
    meta: {
      title: '统计分析',
    },
  },
  // 收车经理页面
  {
    name: 'managergongchecarList',
    path: '/managergongchecarList',
    component: () => import('./view/manager/gongchecarList'),
    meta: {
      title: '客户供车列表',
    },
  },
  {
    name: 'managergongcheCarDetails',
    path: '/managergongcheCarDetails',
    component: () => import('./view/manager/gongchecarDetail'),
    meta: {
      title: '车辆详细信息',
    },
  },
  {
    name: 'managerCustomerList',
    path: '/managerCustomerList',
    component: () => import('./view/manager/customerList'),
    meta: {
      title: '客户列表',
    },
  },
  {
    name: 'managerAddCustomer',
    path: '/managerAddCustomer',
    component: () => import('./view/manager/customerAdd'),
    meta: {
      title: '新增客户信息',
    },
  },
  {
    name: 'managerCustomerDetail',
    path: '/managerCustomerDetail',
    component: () => import('./view/manager/customerDetail'),
    meta: {
      title: '查看客户信息',
    },
  },
  {
    name: 'managerCarList',
    path: '/managerCarList',
    component: () => import('./view/manager/carList'),
    meta: {
      title: '车辆列表',
    },
  },
  {
    name: 'managerCarDetails',
    path: '/managerCarDetails',
    component: () => import('./view/manager/carDetail'),
    meta: {
      title: '车辆详细信息',
    },
  },
  {
    name: 'managerAddCar',
    path: '/managerAddCar',
    component: () => import('./view/manager/carAdd'),
    meta: {
      title: '新增车辆信息',
    },
  },
  {
    name: 'managerPaymentList',
    path: '/managerPaymentList',
    component: () => import('./view/manager/paymentList'),
    meta: {
      title: '催付款车辆列表',
    },
  },
  {
    name: 'managerAnalysis',
    path: '/managerAnalysis',
    component: () => import('./view/manager/analysis'),
    meta: {
      title: '统计分析',
    },
  },
  // 经理助理页面
  {
    name: 'jinglizhuliCustomerList',
    path: '/jinglizhuliCustomerList',
    component: () => import('./view/jinglizhuli/customerList'),
    meta: {
      title: '客户列表',
    },
  },
  {
    name: 'jinglizhuliAddCustomer',
    path: '/jinglizhuliAddCustomer',
    component: () => import('./view/jinglizhuli/customerAdd'),
    meta: {
      title: '新增客户信息',
    },
  },
  {
    name: 'jinglizhuliCustomerDetail',
    path: '/jinglizhuliCustomerDetail',
    component: () => import('./view/jinglizhuli/customerDetail'),
    meta: {
      title: '查看客户信息',
    },
  },
  {
    name: 'jinglizhuliCarList',
    path: '/jinglizhuliCarList',
    component: () => import('./view/jinglizhuli/carList'),
    meta: {
      title: '车辆列表',
    },
  },
  {
    name: 'jinglizhuliCarDetails',
    path: '/jinglizhuliCarDetails',
    component: () => import('./view/jinglizhuli/carDetail'),
    meta: {
      title: '车辆详细信息',
    },
  },
  {
    name: 'jinglizhuliAddCar',
    path: '/jinglizhuliAddCar',
    component: () => import('./view/jinglizhuli/carAdd'),
    meta: {
      title: '新增车辆信息',
    },
  },
  // 拆解员
  {
    name: 'chaijieyuanCarDetails',
    path: '/chaijieyuanCarDetails',
    component: () => import('./view/chaijieyuan/carDetail'),
    meta: {
      title: '车辆详细信息',
    },
  },
  {
    name: 'chaijieyuanCarList',
    path: '/chaijieyuanCarList',
    component: () => import('./view/chaijieyuan/carList'),
    meta: {
      title: '车辆列表',
    },
  },
  // 物流员页面
  {
    name: 'wuliuyuanCarList',
    path: '/wuliuyuanCarList',
    component: () => import('./view/wuliuyuan/carList'),
    meta: {
      title: '车辆列表',
    },  
  },
  {
    name: 'wuliuyuanCarDetail',
    path: '/wuliuyuanCarDetail',
    component: () => import('./view/wuliuyuan/carDetail'),
    meta: {
      title: '车辆详细信息',
    },
  },
  // outboundDetail
  {
    name: 'wuliuyuanoutboundDetail',
    path: '/wuliuyuanoutboundDetail',
    component: () => import('./view/wuliuyuan/outboundDetail'),
    meta: {
      title: '出库详细信息',
    },
  },
  // 拖车员页面
  {
    name: 'trailerCarList',
    path: '/trailerCarList',
    component: () => import('./view/trailer/carList'),
    meta: {
      title: '车辆列表',
    },  
  },
  {
    name: 'trailerCarDetail',
    path: '/trailerCarDetail',
    component: () => import('./view/trailer/carDetail'),
    meta: {
      title: '车辆详细信息',
    },
  },
  {
    name: 'trailerCarDetail2',
    path: '/trailerCarDetail2',
    component: () => import('./view/trailer/carDetail2'),
    meta: {
      title: '车辆详细信息',
    },
  },
    // 文员页面
    {
      name: 'wenyuanCarList',
      path: '/wenyuanCarList',
      component: () => import('./view/wenyuan/carList'),
      meta: {
        title: '车辆列表',
      },  
    },
    {
      name: 'wenyuanCarDetail',
      path: '/wenyuanCarDetail',
      component: () => import('./view/wenyuan/carDetail'),
      meta: {
        title: '车辆详细信息',
      },
    },
  // 上海文员页面
  {
    name: 'shanghaiwenyuanCarList',
    path: '/shanghaiwenyuanCarList',
    component: () => import('./view/shanghaiwenyuan/carList'),
    meta: {
      title: '车辆列表',
      keepAlive: true
    },  
  },
  {
    name: 'shanghaiwenyuanCarDetail',
    path: '/shanghaiwenyuanCarDetail',
    component: () => import('./view/shanghaiwenyuan/carDetail'),
    meta: {
      title: '车辆详细信息',
      
    },
  },
  // 文员经理
  {
    name: 'wenyuanjingliCarList',
    path: '/wenyuanjingliCarList',
    component: () => import('./view/wenyuanjingli/carList'),
    meta: {
      title: '车辆列表',
    },  
  },
  {
    name: 'wenyuanjingliCarDetail',
    path: '/wenyuanjingliCarDetail',
    component: () => import('./view/wenyuanjingli/carDetail'),
    meta: {
      title: '车辆详细信息',
    },
  },
  // 工厂财务
  {
    name: 'gongchangcaiwuCarList',
    path: '/gongchangcaiwuCarList',
    component: () => import('./view/gongchangcaiwu/carList'),
    meta: {
      title: '车辆列表',
    },  
  },
  {
    name: 'gongchangcaiwuCarDetail',
    path: '/gongchangcaiwuCarDetail',
    component: () => import('./view/gongchangcaiwu/carDetail'),
    meta: {
      title: '车辆详细信息',
    },
  },
  // 保管员页面
  {
    name: 'custodianWarehousing',
    path: '/custodianWarehousing',
    component: () => import('./view/custodian/warehousing'),
    meta: {
      title: '车辆入库',
    },  
  },
  {
    name: 'custodianWarehousingInfo',
    path: '/custodianWarehousingInfo',
    component: () => import('./view/custodian/warehousingInfo'),
    meta: {
      title: '入库历史记录',
    },  
  },
  {
    name: 'custodianOutbound',
    path: '/custodianOutbound',
    component: () => import('./view/custodian/outbound'),
    meta: {
      title: '车辆出库',
    },  
  },
  {
    name: 'custodianOutboundDetail',
    path: '/custodianOutboundDetail',
    component: () => import('./view/custodian/outboundDetail'),
    meta: {
      title: '出库历史记录',
    },  
  },
  {
    name: 'custodianHistory',
    path: '/custodianHistory',
    component: () => import('./view/custodian/history'),
    meta: {
      title: '历史记录',
    },
  },
  {
    name: 'custodingCarDetail',
    path: '/custodingCarDetail',
    component: () => import('./view/custodian/carDetail'),
    meta: {
      title: '车辆签收',
    },
  },
  {
    name: 'custodingCarAdd',
    path: '/custodingCarAdd',
    component: () => import('./view/custodian/carAdd'),
    meta: {
      title: '快捷收车',
    },
  },
  // 停车场管理员页面
  {
    name: 'parkadeHaulingList',
    path: '/parkadeHaulingList',
    component: () => import('./view/parkade/haulingList'),
    meta: {
      title: '托运列表',
    },  
  },
  {
    name: 'parkadeHaulingSign',
    path: '/parkadeHaulingSign',
    component: () => import('./view/parkade/haulingSign'),
    meta: {
      title: '托运签收',
    },  
  },
  {
    name: 'wenyuanHaulingSign',
    path: '/wenyuanHaulingSign',
    component: () => import('./view/wenyuan/haulingSign'),
    meta: {
      title: '入场详细记录',
    },  
  },
  {
    name: 'parkadecarAdd',
    path: '/parkadecarAdd',
    component: () => import('./view/parkade/carAdd'),
    meta: {
      title: '快捷收车',
    },  
  },
  {
    name: 'parkadecarDetail',
    path: '/parkadecarDetail',
    component: () => import('./view/parkade/carDetail'),
    meta: {
      title: '车辆信息',
    },  
  },
  {
    name: 'parkadehistory',
    path: '/parkadehistory',
    component: () => import('./view/parkade/history'),
    meta: {
      title: '历史记录',
    },  
  },
  {
    name: 'parkadeHaulingSign',
    path: '/parkadeHaulingSign',
    component: () => import('./view/parkade/haulingSign'),
    meta: {
      title: '托运签收',
    },  
  },
  {
    name: 'parkadewarehousing',
    path: '/parkadewarehousing',
    component: () => import('./view/parkade/warehousing'),
    meta: {
      title: '小车入厂',
    },  
  },
  {
    name: 'parkadewarehousingInfo',
    path: '/parkadewarehousingInfo',
    component: () => import('./view/parkade/warehousingInfo'),
    meta: {
      title: '车辆信息',
    },  
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
